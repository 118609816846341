import { ScrollBarStyles } from 'components/Common'
import WalletModal from 'components/WalletModal'
import useDisableScrolling from 'hooks/useDisableScrolling'
import usePrevious from 'hooks/usePrevious'
import { useWindowSize } from 'hooks/useWindowSize'
import { atom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useGesture } from 'react-use-gesture'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { Z_INDEX } from 'theme/zIndex'

const DRAWER_WIDTH_XL = '390px'
const DRAWER_WIDTH = '320px'
const DRAWER_MARGIN = '8px'
const DRAWER_OFFSET = '10px'
const DRAWER_TOP_MARGIN_MOBILE_WEB = '72px'

const accountDrawerOpenAtom = atom(false)

export function useToggleAccountDrawer() {
  const updateAccountDrawerOpen = useUpdateAtom(accountDrawerOpenAtom)
  return useCallback(() => {
    updateAccountDrawerOpen((open) => !open)
  }, [updateAccountDrawerOpen])
}

export function useCloseAccountDrawer() {
  const updateAccountDrawerOpen = useUpdateAtom(accountDrawerOpenAtom)
  return useCallback(() => updateAccountDrawerOpen(false), [updateAccountDrawerOpen])
}

export function useAccountDrawer(): [boolean, () => void] {
  const accountDrawerOpen = useAtomValue(accountDrawerOpenAtom)
  return [accountDrawerOpen, useToggleAccountDrawer()]
}

const ScrimBackground = styled.div<{ $open: boolean }>`
  // z-index: ${Z_INDEX.modalBackdrop};
  z-index: 1010;
  overflow: hidden;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: ${({ $open }) => ($open ? 'blur(8px)' : 'none')};
  -webkit-backdrop-filter: ${({ $open }) => ($open ? 'blur(8px)' : 'none')};
  transition: backdrop-filter ${({ theme }) => theme.transition.duration.medium} ease-in-out,
    -webkit-backdrop-filter ${({ theme }) => theme.transition.duration.medium} ease-in-out;
  pointer-events: ${({ $open }) => ($open ? 'none' : 'auto')};
  display: ${({ $open }) => ($open ? 'block' : 'none')};

  // Exclude the area of the WalletModal from the blur
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%; // Adjust these values based on WalletModal's size and position
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%; // Adjust these values based on WalletModal's size and position
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    display: none;
  }
`

interface ScrimBackgroundProps extends React.ComponentPropsWithRef<'div'> {
  $open: boolean
}

export const Scrim = (props: ScrimBackgroundProps) => {
  const { width } = useWindowSize()

  useEffect(() => {
    if (width && width < BREAKPOINTS.sm && props.$open) document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [props.$open, width])

  return <ScrimBackground {...props} />
}

const AccountDrawerScrollWrapper = styled.div`
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }

  ${ScrollBarStyles}

  scrollbar-gutter: stable;
  overscroll-behavior: contain;
  border-radius: 12px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 2 * ${DRAWER_MARGIN});
  overflow: hidden;
  position: fixed;
  right: ${DRAWER_MARGIN};
  top: ${DRAWER_MARGIN};
  z-index: ${Z_INDEX.fixed};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    top: 100vh;
    left: 0;
    right: 0;
    width: 100%;
    overflow: visible;
  }
`

function AccountDrawer() {
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer()
  const wasWalletDrawerOpen = usePrevious(walletDrawerOpen)
  const scrollRef = useRef<HTMLDivElement>(null)
  console.log('account drawer', walletDrawerOpen)
  useEffect(() => {
    if (wasWalletDrawerOpen && !walletDrawerOpen) {
      scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [walletDrawerOpen, wasWalletDrawerOpen])

  // close on escape keypress
  useEffect(() => {
    const escapeKeyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && walletDrawerOpen) {
        event.preventDefault()
        toggleWalletDrawer()
      }
    }

    document.addEventListener('keydown', escapeKeyDownHandler)

    return () => {
      document.removeEventListener('keydown', escapeKeyDownHandler)
    }
  }, [walletDrawerOpen, toggleWalletDrawer])

  // useStates for detecting swipe gestures
  const [yPosition, setYPosition] = useState(0)
  const [dragStartTop, setDragStartTop] = useState(true)
  useDisableScrolling(walletDrawerOpen)

  // useGesture hook for detecting swipe gestures
  const bind = useGesture({
    // if the drawer is open and the user is dragging down, close the drawer
    onDrag: (state) => {
      // if the user is dragging up, set dragStartTop to false
      if (state.movement[1] < 0) {
        setDragStartTop(false)
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'auto'
        }
      } else if (
        (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) &&
        walletDrawerOpen &&
        dragStartTop
      ) {
        toggleWalletDrawer()
      } else if (walletDrawerOpen && dragStartTop && state.movement[1] > 0) {
        setYPosition(state.movement[1])
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'hidden'
        }
      }
    },
    // reset the yPosition when the user stops dragging
    onDragEnd: () => {
      setYPosition(0)
      if (scrollRef.current) {
        scrollRef.current.style.overflowY = 'auto'
      }
    },
    // set dragStartTop to true if the user starts dragging from the top of the drawer
    onDragStart: () => {
      if (!scrollRef.current?.scrollTop || scrollRef.current?.scrollTop < 30) {
        setDragStartTop(true)
      } else {
        setDragStartTop(false)
        if (scrollRef.current) {
          scrollRef.current.style.overflowY = 'auto'
        }
      }
    },
  })

  return (
    <>
      {walletDrawerOpen && <ScrimBackground $open={walletDrawerOpen} />}
      <Container>
        {/* ... */}
        {walletDrawerOpen && <WalletModal />}
        {/* ... */}
      </Container>
    </>
  )
}

export default AccountDrawer
