import { useWallet } from '@solana/wallet-adapter-react'
import { Connection as SolanaConnection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import Loader from 'components/Icons/LoadingSpinner'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { Connection } from 'connection/types'
import { Chain, usePortfolioBalancesQuery } from 'graphql/data/__generated__/types-and-hooks'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'state/hooks'
import styled from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { flexColumnNoWrap } from 'theme/styles'
import { shortenAddress } from 'utils'
import { shortenSolanaAddress } from 'utils/addresses'

const IconWrapper = styled.div`
  ${flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  img {
    ${({ theme }) => !theme.darkMode && `border: 1px solid ${theme.surface3}`};
    border-radius: 12px;
  }
  & > img,
  span {
    height: 60px;
    width: 60px;
  }
`

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  // width: 545px;
  background-color: #fabada;
  padding: 20px;
  border-radius: 40px;
  font-family: 'Montserrat';
  color: white;
  box-sizing: border-box;

  background-color: #fd62a6;

  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    background-color: ${({ theme, disabled }) => !disabled && theme.surface3};
  }
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.surface3};
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    // width: 100%;
  }
`

const Divider = styled.div`
  height: 2px;
  background: white;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  margin-left: -20px;
  background: transparent;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  // width: 545px;
  margin-top: 20px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    // width: 100%;
  }
`

const AccountInfo = styled.div`
  display: flex;
`

const AccountAddress = styled.span`
  color: white;
  margin: auto;
  font-size: 30px;
  font-family: 'Dangrek';
  padding-left: 20px;
`

const WalletName = styled.span`
  color: white;
  margin: auto;
  font-size: 41px;
  padding-left: 20px;
`

const BalanceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const BalanceLabel = styled.span`
  width: 100%;
  font-family: 'Dangrek';
  margin-top: 5px;
  // color: white;
  font-size: 31px;
  color: rgba(255, 255, 255, 0.65);
  position: absolute;
  bottom: 10px;
  left: 20px;
  text-align: left;
`

const AccountBalance = styled.span`
  width: 100%;
  font-family: 'Dangrek';
  color: white;
  font-size: 31px;
  color: rgba(255, 255, 255, 0.65);
  position: absolute;
  bottom: 10px;
  right: 20px;
  text-align: right;

  & > span {
    font-family: 'Dangrek';
  }
`
type ChainType = 'mainnet' | 'devnet' | 'testnet'

enum MenuState {
  DEFAULT,
  SETTINGS,
  LANGUAGE_SETTINGS,
  LOCAL_CURRENCY_SETTINGS,
}

interface OptionProps {
  connection: Connection
  handleClick?: any
}
export default function Option({ connection, handleClick }: OptionProps) {
  const { account, connector } = useWeb3React()
  const { publicKey } = useWallet()
  // const solanaAcc = connection.connector.customProvider.isConnected
  const dispatch = useAppDispatch()
  const [balance, setBalance] = useState('')
  const [wallet, setWallet] = useState('')
  const [isSolana, setIsSolana] = useState(false)
  const { activationState, tryActivation } = useActivationState()
  const toggleAccountDrawer = useToggleAccountDrawer()
  const { chainId } = useWeb3React()
  // console.log('activate option', connection)
  const activate = () => tryActivation(connection, handleClick)

  const isSomeOptionPending = activationState.status === ActivationStatus.PENDING
  const isCurrentOptionPending = isSomeOptionPending && activationState.connection.type === connection.type
  const isDarkMode = useIsDarkMode()

  const { data: portfolioBalances } = usePortfolioBalancesQuery({
    variables: { ownerAddress: account ?? '', chains: [Chain.Ethereum] },
  })

  async function fetchBalances(publicKey: string) {
    const fetchedBalance = await getBalance(publicKey)
    setWallet(publicKey)
    setBalance(fetchedBalance)
    setIsSolana(true)
  }

  const getEndpointForChain = (chain: ChainType): string => {
    if (chain === 'devnet') return 'https://api.devnet.solana.com'
    if (chain === 'testnet') return 'https://api.testnet.solana.com'

    return 'https://devnet.helius-rpc.com/?api-key=a1192da4-33e3-40d0-838b-0c57e05385a0'
  }

  async function getBalance(address: string | PublicKey | undefined) {
    console.log('start balance')
    const pubKey = new PublicKey(address as string)

    if (!pubKey) return '0'

    const balance = await new SolanaConnection(getEndpointForChain('mainnet')).getBalance(pubKey)
    console.log('get balance', balance)
    if (!balance) return '0'

    const balanceInSol = (balance / LAMPORTS_PER_SOL).toFixed(3)
    console.log('balance in sol', balanceInSol.toString())
    console.log('balance', balance)

    return balanceInSol
  }

  useEffect(() => {
    console.log('conncetion', connection, publicKey)
    if (connection.getName() === 'Phantom' && publicKey) {
      fetchBalances(publicKey.toString())
    }
    if ((connection.getName() === 'MetaMask' || connection.getName() === 'WalletConnect') && account) {
      // console.log('portfolio', portfolioBalances, account)
      const portfolio = portfolioBalances?.portfolios?.[0]
      const totalBalance = (portfolio?.tokenBalances?.length && portfolio?.tokenBalances?.[0].quantity) || 0
      const balance = totalBalance?.toFixed(3)
      if (balance) setBalance(balance)
      setWallet(account)
      setIsSolana(false)
    }
  }, [connection, account, portfolioBalances, publicKey])

  return (
    <Wrapper disabled={isSomeOptionPending} onClick={activate}>
      <AccountInfo>
        <IconWrapper>
          <img src={connection.getIcon?.(isDarkMode)} alt={connection.getName()} />
        </IconWrapper>
        {!wallet ? (
          <WalletName>
            {connection.getName() === 'Phantom'
              ? 'Phantom'
              : connection.getName() === 'WalletConnect'
              ? 'WalletConnect'
              : 'MetaMask'}
          </WalletName>
        ) : (
          <AccountAddress>{isSolana ? shortenSolanaAddress(wallet) : shortenAddress(wallet)}</AccountAddress>
        )}
      </AccountInfo>
      {wallet ? (
        <>
          <Divider />
          <BalanceWrapper>
            <BalanceLabel>Balance</BalanceLabel>
            <AccountBalance>
              <span style={{ color: 'white' }}>{balance}</span>
              {isSolana ? ' SOL' : ' ETH'}
            </AccountBalance>
          </BalanceWrapper>
        </>
      ) : (
        <></>
      )}
      {isCurrentOptionPending && <Loader />}
    </Wrapper>
  )
}
