import { ConnectionType } from './types'

export interface ConnectionMeta {
  type: ConnectionType
  address?: string
  ENSName?: string
}

export const connectionMetaKey = 'connection_meta'

export function getPersistedConnectionMeta(): ConnectionType[] | undefined {
  try {
    const rawArrayValue = localStorage.getItem(connectionMetaKey)
    console.log('getPersistedConnectionMeta', rawArrayValue)
    const result = []
    if (rawArrayValue) {
      const walletsTypes = JSON.parse(rawArrayValue)
      for (const type of walletsTypes) {
        result.push(type)
      }
      return result
    }
  } catch (e) {
    console.warn(e)
  }
  return
}

export function setPersistedConnectionMeta(type: ConnectionType) {
  const currentMetas = getPersistedConnectionMeta() || []

  if (currentMetas.includes(type)) return
  currentMetas.push(type)

  localStorage.setItem(connectionMetaKey, JSON.stringify(currentMetas))
}

export function deletePersistedConnectionMeta(type?: ConnectionType) {
  if (!type) {
    return localStorage.removeItem(connectionMetaKey)
  }
  const currentMetas = getPersistedConnectionMeta() || []
  const updatedMetas = currentMetas.filter((metaType) => metaType !== type)
  localStorage.setItem(connectionMetaKey, JSON.stringify(updatedMetas))
}
