import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfacePageName, SharedEventName } from '@uniswap/analytics-events'
import { Trace, TraceEvent } from 'analytics'
import { AboutFooter } from 'components/About/AboutFooter'
import { MAIN_CARDS } from 'components/About/constants'
import { useAccountDrawer } from 'components/AccountDrawer'
import { BaseButton } from 'components/Button'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import Swap from 'pages/Swap'
import { darken } from 'polished'
import { parse } from 'qs'
import { useCallback, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link as NativeLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { Z_INDEX } from 'theme/zIndex'

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow: hidden;
`

const Gradient = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 550px;

  z-index: ${Z_INDEX.under_dropdown};
  pointer-events: none;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`

const GlowContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`

const Glow = styled.div`
  position: absolute;
  top: 108px;
  bottom: 0;
  background: radial-gradient(72.04% 72.04% at 50% 3.99%, #ff37eb 0%, rgba(166, 151, 255, 0) 100%);
  // filter: blur(72px);
  // border-radius: 24px;
  max-width: 480px;
  width: 100%;
  height: 100%;
`

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  // justify-content: center;
  width: 100%;
  padding: 0 0 40px;
  max-width: min(720px, 90%);
  min-height: 435px;
  z-index: ${Z_INDEX.under_dropdown};
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};
  height: ${({ theme }) => `calc(90vh - ${theme.navHeight + theme.mobileBottomBarHeight}px)`};
  pointer-events: none;
  * {
    pointer-events: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 70vh;
  }
`

const LandingButton = styled(BaseButton)`
  padding: 16px 0px;
  border-radius: 44px;
`

const ButtonCTA = styled(LandingButton)`
  // background: linear-gradient(93.06deg, #fd62a6 2.66%, #ff9ffb 98.99%);
  background: #fd62a6;
  border: none;
  height: 100px;
  width: 480px;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  box-shadow: -3px 0 0 #fff, 3px 0 0 #fff, 0 -3px 0 #fff, 0 6px 0 #fff;

  text-decoration: none;
  max-width: 480px;
  width: 100%;

  &:hover {
    box-shadow: 0px 0px 16px 0px #ff00c7;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 71px;
  }
`

const ButtonCTAText = styled.p`
  margin: 0px;
  font-size: 45px;
  font-weight: 400;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 60px;
  }
`

const ActionsContainer = styled.span`
  max-width: 400px;
  width: 100%;
  heigh: 100px;
  pointer-events: auto;

  // @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
  //   height: 71px;
  // }
`

const AboutContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px 5rem;
  width: 100%;
  background: #fe9ece;
  height: 100px;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding: 0 96px 5rem;
    height: 130px;
  }
`

const LandingSwapContainer = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`

const SwapCss = css`
  * {
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-10px);
    transition: ${({ theme }) => `transform ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  }
`

const LinkCss = css`
  text-decoration: none;
  max-width: 480px;
  width: 100%;
`

const FULL_BORDER_RADIUS = 9999
const flexRowNoWrap = css`
  display: flex;
  flex-flow: row nowrap;
`

const Web3StatusConnectWrapper = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  background-color: ${({ theme }) => theme.accent2};
  border-radius: ${FULL_BORDER_RADIUS}px;
  border: none;
  padding: 0;
  height: 40px;

  color: ${({ theme }) => theme.accent1};
  :hover {
    color: ${({ theme }) => theme.accent1};
    stroke: ${({ theme }) => theme.accent2};
    background-color: ${({ theme }) => darken(0.015, theme.accent2)};
  }

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};
`

const LandingSwap = styled(Swap)`
  ${SwapCss}// &:hover {
  //   border: 1px solid ${({ theme }) => theme.accent1};
  // }
`

const Link = styled(NativeLink)`
  ${LinkCss}
`

export default function Landing() {
  const isDarkMode = useIsDarkMode()
  // const toggleWalletDrawer = useToggleAccountDrawer()
  const [, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    toggleAccountDrawer()
  }, [toggleAccountDrawer])
  const cardsRef = useRef<HTMLDivElement>(null)
  // const selectedWallet = useAppSelector((state) => state.user.selectedWallet)

  const shouldDisableNFTRoutes = useDisableNFTRoutes()
  const cards = useMemo(
    () => MAIN_CARDS.filter((card) => !(shouldDisableNFTRoutes && card.to.startsWith('/nft'))),
    [shouldDisableNFTRoutes]
  )

  const [accountDrawerOpen] = useAccountDrawer()
  const navigate = useNavigate()
  // useEffect(() => {
  //   if (accountDrawerOpen) {
  //     setTimeout(() => {
  //       navigate('/swap')
  //     }, TRANSITION_DURATIONS.fast)
  //   }
  // }, [accountDrawerOpen, navigate])

  const location = useLocation()
  const queryParams = parse(location.search, { ignoreQueryPrefix: true })

  // if (selectedWallet && !queryParams.intro) {
  //   return <Navigate to={{ ...location, pathname: '/swap' }} replace />
  // }

  return (
    <Trace page={InterfacePageName.LANDING_PAGE} shouldLogImpression>
      <PageContainer data-testid="landing-page">
        <LandingSwapContainer>
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SharedEventName.ELEMENT_CLICKED}
            element={InterfaceElementName.LANDING_PAGE_SWAP_ELEMENT}
          >
            {/* <Link to="/swap"> */}
            <LandingSwap />
            {/* </Link> */}
          </TraceEvent>
        </LandingSwapContainer>
        <Gradient isDarkMode={isDarkMode} />
        <GlowContainer>{/* <Glow /> */}</GlowContainer>
        <ContentContainer isDarkMode={isDarkMode}>
          <ActionsContainer>
            <Web3StatusConnectWrapper
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
              onClick={handleWalletDropdownClick}
            >
              <ButtonCTA>
                <ButtonCTAText>
                  <Trans>GET MY NFT</Trans>
                </ButtonCTAText>
              </ButtonCTA>
            </Web3StatusConnectWrapper>
          </ActionsContainer>
        </ContentContainer>
        <AboutContentContainer isDarkMode={isDarkMode}>
          <AboutFooter />
        </AboutContentContainer>
      </PageContainer>
    </Trace>
  )
}
