import logo from 'assets/images/lil-logo.png'
import menu from 'assets/svg/menu.svg'
import { useAccountDrawer } from 'components/AccountDrawer'
import Web3Status from 'components/Web3Status'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { ReactNode, useCallback, useState } from 'react'
import { NavLink, NavLinkProps, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

import { DiscordIcon, InstagramIcon, XIcon } from '../About/Icons'
import CloseMenu from '../About/images/close-menu.png'
import TiktokLogo from '../About/images/tiktok_logo.png'
import YoutubeLogo from '../About/images/youtube-logo.png'
import * as styles from './style.css'

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
}

const TextWrapper = styled.div`
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  margin-left: 20px;
  font-size: 80px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    margin-left: 0px;
    font-size: 57px;
    text-align: center;
  }
`

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 16px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: 1000;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 0px 16px;
    height: 100px;
  }
`

const LogoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 0 10px 0 10px;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    flex: none;
  }
`

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
    justify-content: start;
    flex-grow: 0;
    align-items: center;
    margin-top: 0;
  }
`

const TitleText = styled.h1<{ isDarkMode: boolean }>`
  font-size: 57px;
  color: #fe9ece;
  line-height: 44px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  background-clip: text;
  -webkit-background-clip: text;

  text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff, 2px 2px 0 #fff, 0 2px 0 #fff,
    -2px 2px 0 #fff, -2px 0 0 #fff;
  // -webkit-text-stroke-width: 2px;
  // -webkit-text-stroke-color: white;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    // -webkit-text-stroke-width: 2.5px;
    text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff, 2px 2px 0 #fff, 0 2px 0 #fff,
      -2px 2px 0 #fff, -2px 0 0 #fff;
    font-size: 71px;
    padding-left: 20px;
    line-height: 72px;
    text-align: left; // Align text to the left for web
  }
`
const SubText = styled.div`
  color: #fe9ece;
  font-size: 11px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  font-family: 'Dangrek';
  max-width: 500px;
  margin: 0;
  text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, 0 1px 0 #fff,
    -1px 1px 0 #fff, -1px 0 0 #fff;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    width: 350px;
    font-size: 40px;
    line-height: 38px;
    text-align: center;
    margin-left: auto;
    margin-right: 270px;
    text-shadow: -2px -2px 0 #fff, 0 -2px 0 #fff, 2px -2px 0 #fff, 2px 0 0 #fff, 2px 2px 0 #fff, 0 2px 0 #fff,
      -2px 2px 0 #fff, -2px 0 0 #fff;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: left;
`

const LogoImg = styled.img`
  cursor: pointer;
  // width: 50px;
  margin-top: 5px;
  height: 65px;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    height: 100px;
    width: 80px;
    margin-left: 330px;
  }
`

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  flex: 1;

  & > img {
    margin-top: 5px;
    height: 65px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    visibility: hidden;
  }
`

const StyledSocLogo = styled.img`
  width: 32px;
  height: 32px;
  // display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`

const FullMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fe9ece;
  border-radius: 30px;
  height: 58px;
  // position: relative;
  padding: 0 20px;
  position: fixed;
  right: 0;
  top: 50;
  width: 90%;
  margin: 0 20px 0 20px;
  border: 2px solid white;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-right: 10px;
  margin-top: 2px;
  top: 50%;
  right: 10px; // 10px from the right edge of FullMenu
  transform: translateY(-50%); // Center vertically
  padding: 0;

  img {
    width: 24px; // Set a fixed width for the image if necessary
    height: auto; // Maintain aspect ratio
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    visibility: hidden; // Hide on larger screens if necessary
  }
`

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 50px;
  margin-right: 80px;
  order: 1;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    // visibility: none;
    margin-left: 290px;
    margin-right: 400px;
  }
`

const SocialLink = styled.a`
  // display: none;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    display: flex;
  }
`

const MenuItem = ({ href, dataTestId, id, isActive, children }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={styles.menuItem}
      id={id}
      style={
        {
          // color: '#fe9ece',
          // textAlign: 'center',
          // fontSize: '80px',
          // fontWeight: '400',
          // position: 'relative',
          // boxSizing: 'border-box',
        }
      }
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()

  const handleUniIconClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {/* <Blur /> */}
      <Nav>
        {!menuOpen && (
          <>
            <LogoBox>
              <LogoContainer>
                <LogoImg src={logo} alt="Lilverse Logo" onClick={handleUniIconClick} />
              </LogoContainer>
            </LogoBox>
            <ContentContainer isDarkMode={true}>
              <TitleText isDarkMode={true}>Lilverse</TitleText>
              <SubText>Animations, comics, memes and more!</SubText>
            </ContentContainer>
            <MenuButton onClick={() => setMenuOpen(!menuOpen)}>
              <img src={menu} alt="Menu" />
            </MenuButton>
          </>
        )}

        {menuOpen && (
          <FullMenu>
            <SocialLinks>
              <SocialLink href="https://discord.gg/vYTTpY8Ebp" target="_blank" rel="noopener noreferrer">
                <DiscordIcon size={32} />
              </SocialLink>
              <SocialLink href="https://twitter.com/LilverseHQ" target="_blank" rel="noopener noreferrer">
                <XIcon size={32} />
              </SocialLink>
              <SocialLink href="https://www.tiktok.com/@lilverseofficial" target="_blank" rel="noopener noreferrer">
                <StyledSocLogo src={TiktokLogo} />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/lilverseofficial/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon size={32} />
              </SocialLink>
              <SocialLink href="https://www.youtube.com/@lilverseofficial" target="_blank" rel="noopener noreferrer">
                <StyledSocLogo src={YoutubeLogo} />
              </SocialLink>
            </SocialLinks>
            <CloseButton onClick={() => setMenuOpen(!menuOpen)}>
              <img src={CloseMenu} alt="Close Menu" />
            </CloseButton>
          </FullMenu>
        )}
        <Web3Status />
      </Nav>
    </>
  )
}

export default Navbar
