import logo from 'assets/images/lil-logo.png'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { isMobile } from 'utils/userAgent'

import { DiscordIcon, InstagramIcon, XIcon } from './Icons'
import TiktokLogo from './images/tiktok_logo.png'
import YoutubeLogo from './images/youtube-logo.png'

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  position: relative;
  align-items: center;
  // justify-content: center;
  justify-content: space-between;
  padding: 20px 10px;
  gap: 200px;
  order: 2;

  // @media screen and (min-width: ${BREAKPOINTS.lg}px) {
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: row;
    order: 1;
  }
`

const LogoSection = styled.div`
  // display: flex;
  justify-content: flex-start;
  align-items: center;
  // flex: 1;
  padding-left: 13px;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    // flex: none;
  }
`

const LogoSectionLeft = styled(LogoSection)`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
`

const LogoSectionBottom = styled(LogoSection)`
  display: flex;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`

const StyledLogo = styled.img`
  cursor: pointer;
  width: 80px;
  // margin-left: 10px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 42px;
    // display: none;
  }
`

const StyledSocLogo = styled.img`
  width: 32px;
  height: 32px;
  // display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`

const SocialLinks = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: 50px;
  margin-right: 50px;
  order: 1;

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    // visibility: none;
    margin-left: 275px;
    margin-right: 400px;
  }
`

const SocialLink = styled.a`
  // display: none;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    display: flex;
  }
`

const SubText = styled.div`
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  color: #fe9ece;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  order: 1;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    line-height: 10px;
    font-size: 10px;
    margin-left: 10px;
    // text-align: right;
    // display: none;
  }
`

const SubTextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #fe9ece;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-align: right;
  margin-left: auto;
  margin-right: 35px;
  order: 3;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 10px;
    text-align: right;
    font-size: 10px;
    text-align: center;
    margin-left: 155px;
    margin-right: 0;
    align-self: center; // Center align on mobile view
    order: 3;
  }
`

const CopyrightLine = styled.div`
  display: block;
  color: white;
  font-family: 'Montserrat';
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  font-weight: 500;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 12px;
  }
`

const CopyrightLine0 = styled.div`
  display: block;
  color: white;
  font-family: 'Montserrat';
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  font-weight: 500;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 9px;
  }
`

const LogoSectionContent = () => {
  return (
    <>
      <StyledLogo src={logo} alt="Uniswap Logo" />
      <SubText>
        <CopyrightLine0>© 2024. Lilverse.</CopyrightLine0>
        <CopyrightLine0>All rights reserved.</CopyrightLine0>
      </SubText>
      {!isMobile && (
        <SocialLinks>
          <SocialLink href="https://discord.gg/vYTTpY8Ebp" target="_blank" rel="noopener noreferrer">
            <DiscordIcon size={32} />
          </SocialLink>
          <SocialLink href="https://twitter.com/LilverseHQ" target="_blank" rel="noopener noreferrer">
            <XIcon size={32} />
          </SocialLink>
          <SocialLink href="https://www.tiktok.com/@lilverseofficial" target="_blank" rel="noopener noreferrer">
            <StyledSocLogo src={TiktokLogo} />
          </SocialLink>
          <SocialLink href="https://www.instagram.com/lilverseofficial/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon size={32} />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/@lilverseofficial" target="_blank" rel="noopener noreferrer">
            <StyledSocLogo src={YoutubeLogo} />
          </SocialLink>
        </SocialLinks>
      )}
      <SubTextRight>
        <CopyrightLine>Privacy policy.</CopyrightLine>
      </SubTextRight>
    </>
  )
}

export const AboutFooter = () => {
  return (
    <Footer>
      <LogoSectionLeft>
        <LogoSectionContent />
      </LogoSectionLeft>

      <LogoSectionBottom>
        <LogoSectionContent />
      </LogoSectionBottom>
    </Footer>
  )
}
