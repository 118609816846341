import { useWeb3React } from '@web3-react/core'
import { useAccountDrawer } from 'components/AccountDrawer'
import { BaseButton } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { connections } from 'connection'
import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import styled from 'styled-components'
import { flexColumnNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'
import { isMobile } from 'utils/userAgent'

import close from '../../assets/images/close.png'
import lil from '../../assets/images/lil-g.png'
import Option from './Option'

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: #fe9ece; /* Replace with your theme color */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 658px;
  height: 751px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: ${Z_INDEX.modal};
  // padding: 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    border-radius: 0;
    width: 100%;
    margin-top: 100px;
    // margin: auto;
    height: 100%;
  }
`
const WrapperSmall = styled.div`
  ${flexColumnNoWrap};
  background-color: #fe9ece; /* Replace with your theme color */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 658px;
  height: auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: ${Z_INDEX.modal};
  padding-top: 20px;
  padding-bottom: 20px;
  // padding: 32px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    border-radius: 0;
    width: 100%;
    margin-top: 100px;
    // margin: auto;
    top: 45%;
    height: 90%;
  }
`

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-top: 0;
  }
`

const SubText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  color: white;
  font-size: 86px;
  // line-height: 24px;
  // font-weight: 400;
  // text-align: left;
  // order: 1;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 56px;
    margin-left: 0px;
    // text-align: right;
    // display: none;
  }
`

const SubTextHeader2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-top: 20px;
  color: white;
  font-size: 63px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 45px;
    // text-align: right;
    // display: none;
  }
`

const WelcomeHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-top: 40px;
  color: white;
  font-size: 63px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 75px;
    line-height: 65px;
    margin-top: 0;
  }
`

const SubText2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-transform: none;
  font-size: 29px;
  margin: 0 40px 0 40px;
  font-family: 'Dangrek';
  line-height: 30px;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin: 0;
    font-size: 18px;
    // text-align: right;
    // display: none;
  }
`

const SubText3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-transform: none;
  font-size: 25px;
  margin: 0 40px 0 40px;
  line-height: 30px;
  font-family: 'Dangrek';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 18px;
    margin: 0;
    // text-align: right;
    // display: none;
  }
`

const WalletsText = styled.div`
  display: flex;
  align-items: left;
  color: white;
  text-transform: none;
  font-size: 25px;
  // margin-left: 20px;
  margin: 10px 0 2px 0;
  font-family: 'Dangrek';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 15px;
    // text-align: right;
    // display: none;
  }
`

const TermsOfServiceText = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 30px;
  color: white;
  font-size: 25px;
  margin: 0 40px 0 40px;
  font-family: 'Dangrek';

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 15px;
    // text-align: right;
    // display: none;
  }
`

const LandingButton = styled(BaseButton)`
  padding: 16px 0px;
  border-radius: 44px;
`

const ActionsContainer = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  heigh: 100px;
  pointer-events: auto;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    margin-top: 20px;
    width: 85%;
  }
`

const ButtonCTA = styled(LandingButton)`
  // background: linear-gradient(93.06deg, #fd62a6 2.66%, #ff9ffb 98.99%);
  background: #fd62a6;
  border: none;
  height: 94px;
  width: 545px;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  box-shadow: -3px 0 0 #fff, 3px 0 0 #fff, 0 -3px 0 #fff, 0 6px 0 #fff;

  text-decoration: none;
  // max-width: 480px;
  // width: 100%;

  &:hover {
    box-shadow: 0px 0px 16px 0px #ff00c7;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    height: 60px;
    width: 100%;
  }
`

const ButtonCTAText = styled.p`
  margin: 0px;
  font-size: 60px;
  font-weight: 400;
  white-space: nowrap;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    font-size: 45px;
  }
`

const StyledImage = styled.img`
  display: block;
  width: auto;
  height: 270px;
  max-width: 100%;
  margin: auto;
  // margin-left: -10px;
  // margin-top: -50px;
  // margin-right: auto;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    // margin-left: auto;
    margin-top: -100px;
    // padding-right: 20px;
    // height: 390px;
  }
`

const OptionGrid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 2px;
  // border-radius: 40px;
  overflow: hidden;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    grid-template-columns: 1fr;
  `};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    // margin: 0 10px 0 10px;
    width: auto;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 26px;
  right: 26px;
  background: transparent;
  border: none;
  cursor: pointer;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-right: -15px;
  }
`
const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  // You might want to add more styles here depending on your design
`

const STEP_INITIAL = 'initial'
const STEP_SELECT_WALLET_ETH = 'select_wallet_eth'
const STEP_CONNECT_SOLANA = 'connect_solana'
const STEP_SELECT_SOLANA = 'select_solana'
const STEP_WELCOME = 'welcome'

export default function WalletModal({ openSettings }: { openSettings?: () => void }) {
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer()
  const { account } = useWeb3React()
  const [currentStep, setCurrentStep] = useState(STEP_INITIAL)
  const [isSolanaConnected, setIsSolanaConnected] = useState(false)

  useEffect(() => {
    for (const connection of connections) {
      if (connection.getName() === 'Phantom' && connection.connector.isConnected) {
        setIsSolanaConnected(true)
      }
    }
  }, connections)

  useEffect(() => {
    console.log('step', currentStep, account, isSolanaConnected)
    // setCurrentStep(STEP_INITIAL)
    // setCurrentStep(STEP_SELECT_SOLANA)
    // setCurrentStep(STEP_CONNECT_SOLANA)
    // setCurrentStep(STEP_SELECT_WALLET_ETH)
    // setCurrentStep(STEP_WELCOME)
    if (!account && !isSolanaConnected) {
      setCurrentStep(STEP_INITIAL)
    }
    if (account && !isSolanaConnected && currentStep !== STEP_CONNECT_SOLANA) {
      setCurrentStep(STEP_CONNECT_SOLANA)
    }
    if (!account && isSolanaConnected && currentStep !== STEP_SELECT_WALLET_ETH) {
      setCurrentStep(STEP_SELECT_WALLET_ETH)
    }
    if (account && isSolanaConnected && currentStep !== STEP_WELCOME) {
      setCurrentStep(STEP_WELCOME)
    }
  }, [isSolanaConnected, account])

  const handleGetStartedClick = () => {
    setCurrentStep(STEP_SELECT_WALLET_ETH)
  }

  const handleConnectClick = (step: string) => {
    console.log('handle click', step)
    if (step) {
      setCurrentStep(step)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_INITIAL:
        return (
          <Wrapper data-testid="wallet-modal">
            <CloseButton onClick={toggleWalletDrawer}>
              <CloseIcon src={close} alt="Close" />
            </CloseButton>
            <AutoColumn gap={isMobile ? '0' : '20px'}>
              <StyledImage src={lil} alt="Character" />
              <ContentContainer isDarkMode={true}>
                <SubText>CONNECT TO ETH</SubText>
                <SubText2>You`ll need to connect both your Ethereum</SubText2>
                <SubText2>and Solana wallets to get started</SubText2>
              </ContentContainer>

              <ActionsContainer onClick={handleGetStartedClick}>
                <ButtonCTA>
                  <ButtonCTAText>GET STARTED</ButtonCTAText>
                </ButtonCTA>
              </ActionsContainer>

              <ContentContainer isDarkMode={true}>
                <TermsOfServiceText>By connecting a wallet, you agree to our</TermsOfServiceText>
                <TermsOfServiceText>Terms of Service.</TermsOfServiceText>
              </ContentContainer>
            </AutoColumn>
          </Wrapper>
        )

      case STEP_SELECT_WALLET_ETH:
        return (
          <WrapperSmall data-testid="wallet-modal">
            <CloseButton onClick={toggleWalletDrawer}>
              <CloseIcon src={close} alt="Close" />
            </CloseButton>
            <AutoColumn gap={isMobile ? '0' : '20px'}>
              <StyledImage src={lil} alt="Character" />
              <ContentContainer isDarkMode={true}>
                <SubTextHeader2>SELECT YOUR WALLET</SubTextHeader2>
              </ContentContainer>
              <OptionGrid data-testid="option-grid">
                {connections
                  .filter(
                    (connection) =>
                      connection.shouldDisplay() &&
                      (connection.getName() === 'MetaMask' || connection.getName() === 'WalletConnect')
                  )
                  .map((connection) => (
                    <Option
                      key={connection.getName()}
                      connection={connection}
                      handleClick={handleConnectClick(STEP_CONNECT_SOLANA)}
                    />
                  ))}
              </OptionGrid>

              <ContentContainer isDarkMode={true}>
                <TermsOfServiceText>By connecting a wallet, you agree to our</TermsOfServiceText>
                <TermsOfServiceText>Terms of Service.</TermsOfServiceText>
              </ContentContainer>
            </AutoColumn>
          </WrapperSmall>
        )
      case STEP_CONNECT_SOLANA:
        return (
          <WrapperSmall data-testid="wallet-modal">
            <CloseButton onClick={toggleWalletDrawer}>
              <CloseIcon src={close} alt="Close" />
            </CloseButton>
            <AutoColumn gap={isMobile ? '0' : '20px'}>
              <StyledImage src={lil} alt="Character" />
              <ContentContainer isDarkMode={true}>
                <SubTextHeader2>SELECT YOUR WALLET</SubTextHeader2>
              </ContentContainer>
              <OptionGrid data-testid="option-grid">
                {connections
                  .filter((connection) => connection.shouldDisplay() && connection.getName() === 'Phantom')
                  .map((connection) => (
                    <Option
                      key={connection.getName()}
                      connection={connection}
                      handleClick={handleConnectClick(STEP_WELCOME)}
                    />
                  ))}
              </OptionGrid>

              <ContentContainer isDarkMode={true}>
                <TermsOfServiceText>By connecting a wallet, you agree to our</TermsOfServiceText>
                <TermsOfServiceText>Terms of Service.</TermsOfServiceText>
              </ContentContainer>
            </AutoColumn>
          </WrapperSmall>
        )
      case STEP_WELCOME:
        return (
          <WrapperSmall data-testid="wallet-modal">
            {!isMobile ? (
              <CloseButton onClick={toggleWalletDrawer}>
                <CloseIcon src={close} alt="Close" />
              </CloseButton>
            ) : (
              <></>
            )}
            <AutoColumn gap={isMobile ? '0' : '20px'}>
              <ContentContainer isDarkMode={true}>
                {!isMobile ? (
                  <WelcomeHeader>WELCOME TO LILVERSE</WelcomeHeader>
                ) : (
                  <>
                    <WelcomeHeader>WELCOME</WelcomeHeader>
                    <WelcomeHeader>TO LILVERSE</WelcomeHeader>
                  </>
                )}
                <SubText3>Congratulations on connecting both your</SubText3>
                <SubText3>Ethereum and Solana wallets.</SubText3>
              </ContentContainer>
              <OptionGrid data-testid="option-grid">
                {connections
                  .filter((connection) => connection.shouldDisplay())
                  .map((connection, index) => (
                    <Fragment key={connection.getName()}>
                      {index === 0 && <WalletsText>Ethereum Wallet</WalletsText>}
                      {index === 1 && <WalletsText>Solana Wallet</WalletsText>}
                      <Option key={connection.getName()} connection={connection} handleClick={handleConnectClick} />
                    </Fragment>
                  ))}
              </OptionGrid>

              <ActionsContainer onClick={toggleWalletDrawer}>
                <ButtonCTA>
                  <ButtonCTAText>Continue</ButtonCTAText>
                </ButtonCTA>
              </ActionsContainer>
            </AutoColumn>
          </WrapperSmall>
        )
      default:
        return null
    }
  }
  return renderStepContent()
}
