import { InterfaceEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import PortfolioDrawer, { useAccountDrawer } from 'components/AccountDrawer'
import { IconWrapper } from 'components/Identicon/StatusIcon'
import PrefetchBalancesWrapper from 'components/PrefetchBalancesWrapper/PrefetchBalancesWrapper'
import { phantomConnection } from 'connection'
import { getPersistedConnectionMeta } from 'connection/meta'
import useLast from 'hooks/useLast'
import { Portal } from 'nft/components/common/Portal'
import { darken } from 'polished'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from 'state/hooks'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import { flexRowNoWrap } from 'theme/styles'

import { ButtonSecondary } from '../Button'

// https://stackoverflow.com/a/31617326
const FULL_BORDER_RADIUS = 9999

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${flexRowNoWrap};
  width: 100%;
  align-items: center;
  padding: 0.5rem 0.25rem;
  border-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 2px;
  :focus {
    outline: none;
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{
  pending?: boolean
  isClaimAvailable?: boolean
}>`
  background-color: ${({ pending, theme }) => (pending ? theme.accent1 : theme.surface1)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.accent1 : theme.surface1)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.neutral1)};
  border: ${({ isClaimAvailable }) => isClaimAvailable && `1px solid ${colors.purple300}`};
  :hover,
  :focus {
    border: 1px solid ${({ theme }) => theme.surface2};
    background-color: ${({ pending, theme }) => (pending ? theme.accent2 : theme.surface2)};

    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? darken(0.1, theme.accent1) : darken(0.1, theme.surface3))};
    }
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: ${({ pending }) => !pending && '36px'};

    ${IconWrapper} {
      margin-right: 0;
    }
  }
`

const Web3StatusConnectWrapper = styled.div`
  ${flexRowNoWrap};
  width: 190px;
  align-items: center;
  background-color: ${({ theme }) => theme.accent2};
  border-radius: 20px;
  border: none;
  padding: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.accent1};
  :hover {
    color: ${({ theme }) => theme.accent1};
    stroke: ${({ theme }) => theme.accent2};
    background-color: ${({ theme }) => darken(0.015, theme.accent2)};
  }

  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => `${duration.fast} color ${timing.in}`};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 95px;
    height: 40px;
  }
`

const StyledConnectButton = styled.button`
  background-color: transparent;
  border: none;
  border-top-left-radius: ${FULL_BORDER_RADIUS}px;
  border-bottom-left-radius: ${FULL_BORDER_RADIUS}px;
  cursor: pointer;
  font-weight: 535;
  font-size: 24px;
  padding: 10px 12px;
  color: inherit;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    width: 100%;
    font-size: 16px;
  }
`

function Web3StatusInner() {
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
  const ignoreWhileSwitchingChain = useCallback(() => !switchingChain, [switchingChain])
  // const connectionReady = useConnectionReady()
  const activeWeb3 = useWeb3React()
  const lastWeb3 = useLast(useWeb3React(), ignoreWhileSwitchingChain)
  const { account, connector } = useMemo(() => (activeWeb3.account ? activeWeb3 : lastWeb3), [activeWeb3, lastWeb3])
  const [count, setCount] = useState(0)

  // const solanaAccount = phantomConnection.connector.customProvider.isConnected
  const solanaAccount = phantomConnection.connector.isConnected

  const [, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {
    sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
    toggleAccountDrawer()
  }, [toggleAccountDrawer])

  useEffect(() => {
    const count =
      account && phantomConnection.connector && phantomConnection.connector.isConnected ? 2 : account ? 1 : 0
    setCount(count)
  }, [account, phantomConnection.connector.isConnected])

  // Display a loading state while initializing the connection, based on the last session's persisted connection.
  // The connection will go through three states:
  // - startup:       connection is not ready
  // - initializing:  account is available, but ENS (if preset on the persisted initialMeta) is still loading
  // - initialized:   account and ENS are available
  // Subsequent connections are always considered initialized, and will not display startup/initializing states.
  const initialConnections = useRef(getPersistedConnectionMeta())

  // if (account || solanaAccount) {
  //   return (
  //     <TraceEvent
  //       events={[BrowserEvent.onClick]}
  //       name={InterfaceEventName.MINI_PORTFOLIO_TOGGLED}
  //       properties={{ type: 'open' }}
  //     >
  //       <Web3StatusConnectWrapper
  //         tabIndex={0}
  //         onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
  //         onClick={handleWalletDropdownClick}
  //       >
  //         <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet">
  //           <Trans>Connected {!isMobile ? `${count}/2` : ''} </Trans>
  //         </StyledConnectButton>
  //       </Web3StatusConnectWrapper>
  //     </TraceEvent>
  //   )
  // } else {
  //   return (
  //     <TraceEvent
  //       events={[BrowserEvent.onClick]}
  //       name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
  //       element={InterfaceElementName.CONNECT_WALLET_BUTTON}
  //     >
  //       <Web3StatusConnectWrapper
  //         tabIndex={0}
  //         onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
  //         onClick={handleWalletDropdownClick}
  //       >
  //         <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet">
  //           <Trans>Connect</Trans>
  //         </StyledConnectButton>
  //       </Web3StatusConnectWrapper>
  //     </TraceEvent>
  //   )
  // }
}

export default function Web3Status() {
  const [isDrawerOpen] = useAccountDrawer()
  return (
    <PrefetchBalancesWrapper shouldFetchOnAccountUpdate={isDrawerOpen}>
      {/* <Web3StatusInner /> */}
      <Portal>
        <PortfolioDrawer />
      </Portal>
    </PrefetchBalancesWrapper>
  )
}
